import React from 'react';

const Address: React.FC<{
  className?: string;
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  zip?: string;
}> = ({ className = '', addressOne, addressTwo, city, state, zip }) => {
  if (addressOne) {
    return (
      <div className={className}>
        <div className="flex flex-col">
          {addressOne}
          <br />
          {city}, {state} {zip}
        </div>
      </div>
    );
  } else if (city) {
    return (
      <div className={className}>
        {city}, {state} {zip}
      </div>
    );
  } else if (zip) {
    return <div className={className}>Zip {zip}</div>;
  }

  return <div className={className}>Not Provided</div>;
};

export default Address;
