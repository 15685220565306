import React from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

import { Divider, ErrorBoundary } from './';
import QuoteImage from '../assets/img/quote.svg';

const TODAYS_QUOTE_QUERY = gql`
  query todaysQuoteQuery {
    company {
      id
      todaysQuote {
        id
        value
        author
      }
    }
  }
`;

const Quote: React.FC<{
  withBottomDivider?: boolean;
}> = ({ withBottomDivider = false }) => {
  const { loading, data } = useQuery(TODAYS_QUOTE_QUERY);

  if (loading || !data || !data.company?.todaysQuote) {
    return null;
  }

  const { todaysQuote } = data.company;

  return (
    <ErrorBoundary>
      <div className="animated fadeIn mt-6">
        <img src={QuoteImage} alt="Quote" />
        <div className="mt-3 text-base font-light">{todaysQuote.value}</div>
        <div className="bg-primary h-px w-8 mt-3 mb-3" />
        <div className="mt-3 font-semibold">{todaysQuote.author}</div>
      </div>
      {withBottomDivider && <Divider className="my-8" />}
    </ErrorBoundary>
  );
};

export default Quote;
