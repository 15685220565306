import React from 'react';

const Label: React.FC<{
  className?: string;
  label: string;
  hint?: string;
  required?: boolean;
}> = ({ className = '', label, hint, required = false }) => {
  return (
    <>
      <label
        className={`block tracking-wide text-gray-600 text-sm ${
          hint ? 'mb-0' : 'mb-1'
        } ${className}`}
      >
        {required ? (
          <>
            {label} <span className="font-bold text-red-600">*</span>
          </>
        ) : (
          label
        )}
      </label>
      {hint && <p className="mt-0 mb-1 text-xs text-gray-500">{hint}</p>}
    </>
  );
};

export default Label;
