import React from 'react';

import { Label } from '../';
import Error from './Error';

const TextInput: React.FC<{
  autoComplete?: string;
  containerClassName?: string;
  className?: string;
  disabled?: boolean;
  error?: any;
  hint?: string;
  icon?: React.ReactNode;
  innerRef?: any;
  label?: string;
  labelClassName?: string;
  name: string;
  onBlur?: (event: any) => void;
  onChange: (event: any) => void;
  placeholder?: string;
  required?: boolean;
  type?: string;
  value: string | null | undefined;
}> = ({
  autoComplete,
  containerClassName = '',
  className = '',
  disabled = false,
  error,
  hint,
  icon,
  innerRef,
  label,
  labelClassName,
  name,
  onBlur,
  onChange,
  placeholder,
  required = false,
  type,
  value,
}) => {
  let inputClasses =
    'appearance-none block bg-gray-100 text-gray-700 rounded border border-gray-300 py-3 px-4 leading-tight placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out';

  if (error) {
    inputClasses = `${inputClasses} border-red-300 text-red-900`;
  }

  return (
    <div className={containerClassName}>
      <div className="relative">
        {label && (
          <Label
            className={labelClassName}
            hint={hint}
            label={label}
            required={required}
          />
        )}

        {icon && <div className="absolute pt-4 pl-4">{icon}</div>}
        <input
          autoComplete={autoComplete}
          className={`${className} ${inputClasses} ${
            disabled ? 'cursor-not-allowed' : ''
          } ${icon ? 'px-10' : ''}`}
          disabled={disabled}
          id={`grid-${name}`}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          ref={innerRef}
          type={type || 'text'}
          value={value || ''}
        ></input>
      </div>
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default TextInput;
