import { Context, createContext } from 'react';

interface AuthContextType {
  me: User;
  currentUserLoading: boolean;
  signIn: (token: string) => void;
  signOut: () => void;
  setUser: (user: User) => void;
}

const initialContext = {
  me: {
    id: 0,
    name: '',
    firstName: '',
    lastName: '',
    initials: '',
    role: '',
    email: '',
    companyId: 0,
  },
  currentUserLoading: true,
  signIn: (token: string) => {},
  signOut: () => {},
  setUser: (user: User) => {},
};

const AuthContext: Context<AuthContextType> = createContext(initialContext);

export default AuthContext;
