import React from 'react';
import RingLoader from 'react-spinners/RingLoader';

const Spinner: React.FC<{ size?: number; color?: string }> = ({
  size,
  color
}) => {
  return (
    <RingLoader size={size || 50} color={color || '#FF6B00'} loading={true} />
  );
};

export default Spinner;
