import React from 'react';

const Tile: React.FC<{
  containerClassName?: string;
  contentClassName?: string;
}> = ({ children, containerClassName, contentClassName }) => {
  return (
    <div className={`w-full ${containerClassName && containerClassName}`}>
      <div
        className={`rounded shadow-sm bg-primary bg-opacity-5 ${
          contentClassName && contentClassName
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Tile;
