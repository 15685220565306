import { Dispatch } from 'react';
import { gql } from 'apollo-boost';

export const WELCOME_GUIDE_QUERY = gql`
  query welcomeGuideQuery {
    welcome {
      id
      hasWelcomeItems
      coreValuesCompleted
      slackCompleted
      teamsCompleted
      usersCompleted
      companyCompleted
    }
  }
`;

export const onWelcomeSuccess = (
  data: any,
  dispatch: Dispatch<WelcomeAction>
) => {
  if (data && data.welcome) {
    dispatch({
      type: 'networkSuccess',
      payload: data.welcome,
    });
  }
};

export const onWelcomeError = (
  error: any,
  dispatch: Dispatch<WelcomeAction>
) => {
  dispatch({ type: 'networkError' });
};
