import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { gql } from 'apollo-boost';
import { useLocation } from '@reach/router';
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';

import AuthContext from '.';
import {
  authenticationTokenExists,
  clearAuthenticationToken,
  setAuthenticationToken,
} from '../../utils/authUtils';
import {
  WELCOME_GUIDE_QUERY,
  onWelcomeSuccess,
  onWelcomeError,
} from '../../services/graph/welcomeGraph';

import { NavigationContext, WelcomeContext } from '../';

const ME_QUERY = gql`
  query meQuery {
    me {
      id
      authenticationToken
      name
      title
      firstName
      lastName
      initials
      role
      timeZone
      gender
      race
      type
      latitude
      longitude
      hasPhoto
      hasInterests
      hasSkills
      startDate
      team {
        id
        name
        users {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      reports {
        id
        name
      }
      company {
        id
        name
        teams(orderBy: "name") {
          id
          name
        }
        engagements {
          totalCount
        }
        integrations {
          id
          name
        }
        praiseSettings {
          enabled
          aliasName
          aliasVerb
        }
        celebrationSettings {
          enabled
          aliasName
          aliasVerb
        }
      }
    }
  }
`;

const AuthContextProvider: React.FC<{}> = ({ children }) => {
  const { toggleNavMenu } = useContext(NavigationContext);
  const { dispatch } = useContext(WelcomeContext);

  const apolloClient = useApolloClient();
  const location = useLocation();

  const [queryMe, { loading }] = useLazyQuery(ME_QUERY, {
    onCompleted: (data: any) => {
      ReactGA.set({ companyId: data.me.company.id, userId: data.me.id });
      setCurrentUser(data.me);
    },
    onError: () => {
      signOut();
    },
  });

  const [queryWelcomeGuide] = useLazyQuery(WELCOME_GUIDE_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => onWelcomeSuccess(data, dispatch),
    onError: (error) => onWelcomeError(error, dispatch),
  });

  const [me, setCurrentUser] = useState();

  useEffect(() => {
    if (authenticationTokenExists()) {
      queryMe();

      if (location.pathname !== '/welcome') {
        queryWelcomeGuide();
      }
    }
  }, [queryMe, queryWelcomeGuide, location]);

  const signIn = (authenticationToken: string) => {
    setAuthenticationToken(authenticationToken);
    queryMe();
  };

  const signOut = () => {
    setCurrentUser(null);
    clearAuthenticationToken();
    toggleNavMenu(false);
    localStorage.setItem('navMenuOpen', 'false');
    apolloClient.clearStore();
  };

  const setUser = (user: User) => {
    setCurrentUser(user);
  };

  return (
    <AuthContext.Provider
      value={{
        me,
        currentUserLoading: loading,
        signIn,
        signOut,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
