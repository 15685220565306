import React, { useState } from 'react';

import NavigationContext, { initialContext } from '.';

const NavigationContextProvider: React.FC<{}> = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(
    initialContext.mobileMenuOpen
  );
  const [navMenuOpen, setContextMenuOpen] = useState<boolean>(
    initialContext.navMenuOpen
  );

  const toggleMobileMenu = (open?: boolean) => {
    if (typeof open === 'boolean') {
      setMobileMenuOpen(open);
    } else {
      setMobileMenuOpen(!mobileMenuOpen);
    }
  };

  const toggleNavMenu = (open?: boolean) => {
    const openValue: boolean = typeof open === 'boolean' ? open : !navMenuOpen;

    setContextMenuOpen(openValue);
    localStorage.setItem('navMenuOpen', openValue.toString());
  };

  return (
    <NavigationContext.Provider
      value={{
        mobileMenuOpen,
        navMenuOpen,
        toggleMobileMenu,
        toggleNavMenu,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
