import { Context, createContext } from 'react';

interface GoalsContextType {
  currentPeriodId?: number;
  previousPeriodId?: number;
}

const GoalsContext: Context<GoalsContextType> = createContext({});

export default GoalsContext;
