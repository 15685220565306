import React from 'react';
import differenceInYears from 'date-fns/differenceInYears';
import { parseISO } from 'date-fns';
import { gql } from 'apollo-boost';
import { Link } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';

import { Avatar, Section } from '.';
import { stringToLongDate } from '../utils/dataUtils';

const ANNIVERSARIES_QUERY = gql`
  query anniversariesQuery {
    company {
      id
      anniversaries {
        id
        name
        startDate
      }
    }
  }
`;

const Workaverseries: React.FC<{ className?: string }> = ({
  className = '',
}) => {
  const { loading, data } = useQuery(ANNIVERSARIES_QUERY);

  if (loading || !data) {
    return null;
  }

  const { anniversaries } = data.company;

  const renderUsers = () => {
    if (anniversaries.length > 0) {
      return (
        <ul>
          {anniversaries.map((user: User) => {
            const yearDiff = Math.abs(
              differenceInYears(parseISO(user?.startDate!), new Date())
            );

            return (
              <li key={user.id} className="flex items-center py-2">
                <Avatar user={user} size="md" />
                <div className="ml-3">
                  <Link
                    to={`/crew/${user.id}`}
                    className="text-sm font-medium text-gray-900"
                  >
                    {user.name}
                  </Link>
                  <div className="text-sm text-gray-500">
                    {stringToLongDate(user?.startDate!)}
                  </div>
                  {yearDiff > 0 && (
                    <div className="text-sm text-gray-500">
                      {yearDiff} Year Work-a-versary!
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return <div>No Upcoming Work-a-verseries</div>;
    }
  };

  return (
    <Section title="Work-a-versaries" containerClassName={className}>
      {renderUsers()}
    </Section>
  );
};

export default Workaverseries;
