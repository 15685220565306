import React, { Fragment, useContext } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowRightFromBracket,
  faMessageCheck,
} from '@fortawesome/pro-light-svg-icons';

import CrewLogo from '../../assets/img/crew-mark.svg';
import { Avatar, Initials } from '..';
import { AuthContext } from '../../context';
import { pullInitials } from '../../utils/stringUtils';
import { userIsPassenger } from '../../utils/authUtils';

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};

const PassengerNavbar: React.FC<{}> = () => {
  const { me, signOut } = useContext(AuthContext);

  if (!me || !userIsPassenger(me.type!)) {
    return null;
  }

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }: { open: boolean }) => (
        <>
          <div className="px-6 mx-auto">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex items-center flex-shrink-0">
                  <img className="w-auto h-10" src={CrewLogo} alt="Crew" />
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                          <span className="sr-only">Open user menu</span>
                          {me.hasPhoto ? (
                            <Avatar user={me} className="text-xs" />
                          ) : (
                            <Initials initials={pullInitials(me.name)} />
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="absolute right-0 z-50 w-56 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href={`mailto:trent@teamairship.com?subject=Feedback from ${me.name}`}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'flex items-center px-4 py-4 text-sm text-gray-700'
                                )}
                              >
                                <FontAwesomeIcon
                                  icon={faMessageCheck}
                                  className="flex-shrink-0 w-5 h-5 mr-3 text-gray-700"
                                />
                                <div>How can we improve?</div>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'px-4 py-4 text-sm text-gray-700 border-t border-gray-200 flex items-center'
                                )}
                                onClick={() => {
                                  signOut();
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faArrowRightFromBracket}
                                  className="flex-shrink-0 w-5 h-5 mr-3 text-gray-700"
                                />
                                <div>Sign out</div>
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="flex items-center -mr-2 sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <FontAwesomeIcon icon={faBars} className="block w-6 h-6" />
                  ) : (
                    <FontAwesomeIcon icon={faBars} className="block w-6 h-6" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="border-t border-gray-200">
              <div className="space-y-1">
                <a
                  href={`mailto:trent@teamairship.com?subject=Feedback from ${me.name}`}
                  className="block px-4 py-5 text-base font-medium text-center text-white bg-primary"
                >
                  How can we improve?
                </a>
                <a
                  href="/"
                  className="block px-4 py-5 text-base font-medium text-center text-gray-50 hover:text-gray-800 hover:bg-gray-100"
                  onClick={() => {
                    signOut();
                  }}
                >
                  Sign out
                </a>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default PassengerNavbar;
