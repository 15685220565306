import React, { useContext, useEffect, useState } from 'react';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';

import { AuthContext, GoalsContext } from '../';

const SIDEBAR_GOALS_QUERY = gql`
  query sidebarGoalsQuery {
    company {
      currentGoalPeriod {
        id
      }
      previousGoalPeriod {
        id
      }
    }
  }
`;

const GoalsContextProvider: React.FC<{}> = ({ children }) => {
  const { me } = useContext(AuthContext);
  const [currentPeriodId, setCurrentPeriodId] = useState();
  const [previousPeriodId, setPreviousPeriodId] = useState();

  const [fetchGoalPeriods] = useLazyQuery(SIDEBAR_GOALS_QUERY, {
    onCompleted: (data) => {
      if (data && data.company) {
        setCurrentPeriodId(data.company.currentGoalPeriod?.id);
        setPreviousPeriodId(data.company.previousGoalPeriod?.id);
      }
    },
  });

  useEffect(() => {
    if (me) {
      // fetchGoalPeriods();
    }
  }, [me, fetchGoalPeriods]);

  return (
    <GoalsContext.Provider
      value={{
        currentPeriodId,
        previousPeriodId,
      }}
    >
      {children}
    </GoalsContext.Provider>
  );
};

export default GoalsContextProvider;
