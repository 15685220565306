import React, { useContext } from 'react';

import { Menu } from './Icons';
import { NavigationContext } from '../context';

const PageTitle: React.FC<{
  hideMenuToggle?: boolean;
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  accessory?: string | React.ReactNode;
  titleClassName?: string;
  containerClassName?: string;
  underlined?: boolean;
  customWidth?: string;
  crumbs?: Breadcrumb[];
}> = ({
  hideMenuToggle = false,
  title,
  subtitle,
  accessory,
  titleClassName = '',
  containerClassName = '',
  underlined,
  customWidth = '',
  crumbs,
}) => {
  const { navMenuOpen, toggleNavMenu } = useContext(NavigationContext);

  return (
    <>
      <div
        className={`flex flex-wrap justify-between items-center ${containerClassName}`}
      >
        <div
          className={`${
            customWidth
              ? customWidth
              : 'w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3'
          } animated fadeInDown faster`}
        >
          <h1
            className={`font-display text-3xl text-gray-900 leading-none mb-1 justify-start flex ${titleClassName}`}
          >
            {false && (
              <button
                className="hidden text-black focus:outline-none md:block"
                onClick={() => toggleNavMenu(!navMenuOpen)}
              >
                <Menu />
              </button>
            )}
            <span className={true ? '' : 'md:ml-4'}>{title}</span>
          </h1>
          <div
            className={`text-gray-600 text-base font-light mt-2 ${
              true ? '' : 'md:ml-10'
            }`}
          >
            {subtitle}
          </div>
          {underlined && <div className="w-10 h-1 mt-1 bg-primary"></div>}
        </div>

        {accessory}
      </div>
    </>
  );
};

export default PageTitle;
