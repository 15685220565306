import React from 'react';
import Emoji from 'react-emoji-render';
import { Link } from '@reach/router';

import { Avatar } from '..';
import { stringToLongDateTime } from '../../utils/dataUtils';

const PraiseActivity: React.FC<{
  id: string;
  verb: string;
  praise: Praise;
}> = ({ id, verb = 'praised', praise }) => {
  const { praiser, praisees, createdAt } = praise;

  const praiseeList = () => {
    if (praisees?.length === 1) {
      return (
        <Link
          className="font-medium text-gray-900"
          to={`/crew/${praisees[0].id}`}
        >
          {praisees[0].name}
        </Link>
      );
    } else {
      return praisees?.map((praisee: User, idx: number) => {
        if (idx === praisees.length - 1) {
          return (
            <span key={praisee.id}>
              {` and `}
              <Link
                className="font-medium text-gray-900"
                to={`/crew/${praisee.id}`}
              >
                {praisee.name}
              </Link>
            </span>
          );
        } else if (idx === praisees.length - 2) {
          return (
            <Link
              key={praisee.id}
              className="font-medium text-gray-900"
              to={`/crew/${praisee.id}`}
            >
              {praisee.name}
            </Link>
          );
        } else {
          return (
            <span key={praisee.id}>
              <Link
                className="font-medium text-gray-900"
                to={`/crew/${praisee.id}`}
              >
                {praisee.name}
              </Link>
              {', '}
            </span>
          );
        }
      });
    }
  };

  return (
    <li key={id}>
      <div className="relative pb-8">
        <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
        <div className="relative flex items-start space-x-3">
          <div className="relative">
            <Avatar user={praiser} size="md" className="bg-gray-400 flex items-center justify-center ring-8 ring-white" />
          </div>
          <div className="min-w-0 flex-1">
            <div>
              <div className="text-sm text-gray-500">
                <Link to={`/crew/${praiser.id}`} className="font-medium text-gray-900">{`${praiser.name}`}</Link>{' '}{verb}{' '}{praiseeList()}
              </div>
              <p className="mt-0.5 text-sm text-gray-500 mb-0">
                {stringToLongDateTime(createdAt)}
              </p>
            </div>
            <div className="mt-2 text-sm text-gray-700">
              <p className="mb-0">
                {`${praiser.name} ${verb}`} <Emoji text={praise.praise} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default PraiseActivity;
