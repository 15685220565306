import React from 'react';
import { gql } from 'apollo-boost';
import { Link } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';

import { Avatar, Section } from '.';
import { stringToSimpleDate } from '../utils/dataUtils';

const BIRTHDAYS_QUERY = gql`
  query birthdaysQuery {
    company {
      id
      birthdays {
        id
        name
        birthDate
      }
    }
  }
`;

const Birthdays: React.FC<{ className?: string }> = ({ className = '' }) => {
  const { loading, data } = useQuery(BIRTHDAYS_QUERY);

  if (loading || !data) {
    return null;
  }

  const { birthdays } = data.company;

  const renderUsers = () => {
    if (birthdays.length > 0) {
      return (
        <ul>
          {birthdays.map((user: User, idx: number) => {
            return (
              <li key={user.id} className="py-2 flex items-center">
                <Avatar user={user} size="md" />
                <div className="ml-3">
                  <Link
                    to={`/crew/${user.id}`}
                    className="text-sm font-medium text-gray-900"
                  >
                    {user.name}
                  </Link>
                  <div className="text-sm text-gray-500">
                    {stringToSimpleDate(user?.birthDate!)}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return <div>No Upcoming Birthdays</div>;
    }
  };

  return (
    <Section title="Birthdays" containerClassName={className}>
      {renderUsers()}
    </Section>
  );
};

export default Birthdays;
