import React, { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

import {
  Loading,
  ErrorBoundary,
  AnswerActivity,
  PraiseActivity,
  GeneralActivity,
  QuestionActivity,
} from '..';

const ENGAGEMENTS_QUERY = gql`
  query engagementsQuery($after: String) {
    company {
      id
      name
      praiseSettings {
        enabled
        aliasVerb
      }
      celebrationSettings {
        enabled
        aliasVerb
      }
      engagements(
        types: ["Praise", "Celebration", "QuestionSchedule", "Answer"]
        first: 20
        after: $after
      ) {
        totalCount
        totalPageCount
        pageInfo {
          endCursor
          startCursor
          hasPreviousPage
          hasNextPage
        }
        edges {
          node {
            id
            createdAt
            engageableId
            engageableType
            engageable {
              ... on Praise {
                id
                praise
                createdAt
                praiser {
                  id
                  name
                }
                praisees {
                  id
                  name
                }
                coreValues {
                  id
                  value
                  hashtag
                }
              }
              ... on Celebration {
                id
                value
                createdAt
                user {
                  id
                  name
                }
              }
              ... on QuestionSchedule {
                id
                postedAt
                createdAt
                question {
                  id
                  question
                }
                answers {
                  totalCount
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
              ... on Answer {
                id
                value: answer
                createdAt
                user {
                  id
                  name
                }
                question {
                  id
                  question
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Engagements: React.FC<{}> = () => {
  const scrollParentRef = useRef<HTMLUListElement>(null);
  const { loading, data, fetchMore } = useQuery(ENGAGEMENTS_QUERY);

  if (loading || !data) {
    return <Loading />;
  }

  const { engagements, celebrationSettings, praiseSettings } = data.company;

  return (
    <ErrorBoundary>
      <ul
        className="h-screen pb-24 mt-6 overflow-y-auto scrolling-touch animated fadeIn"
        ref={scrollParentRef}
      >
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          // @ts-ignore
          getScrollParent={() => scrollParentRef && scrollParentRef.current}
          loadMore={() => {
            fetchMore({
              variables: {
                after: data.company.engagements.pageInfo.endCursor,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const { engagements } = fetchMoreResult.company;
                const newEdges = engagements.edges;
                const pageInfo = engagements.pageInfo;

                const {
                  engagements: previousEngagements,
                } = previousResult.company;

                return newEdges.length
                  ? {
                      company: {
                        ...previousResult.company,
                        engagements: {
                          ...previousEngagements,
                          __typename: previousEngagements.__typename,
                          edges: [...previousEngagements.edges, ...newEdges],
                          pageInfo,
                        },
                      },
                    }
                  : previousResult;
              },
            });
          }}
          hasMore={engagements.pageInfo.hasNextPage}
          loader={
            <div className="loader" key={0}>
              Loading More ...
            </div>
          }
        >
          {engagements.edges.map((edge: any, idx: number) => {
            const engagement = edge.node;
            const key = `${engagement.id}-${idx}`;

            if (
              engagement.engageableType === 'Praise' &&
              praiseSettings.enabled
            ) {
              return (
                <div key={key} className="mb-6">
                  <PraiseActivity
                    id={key}
                    key={key}
                    verb={praiseSettings.aliasVerb}
                    praise={engagement.engageable}
                  />
                </div>
              );
            } else if (engagement.engageableType === 'Celebration') {
              return (
                <div key={key} className="mb-6">
                  <GeneralActivity
                    id={key}
                    key={key}
                    title={`${celebrationSettings.aliasVerb}!`}
                    verb={celebrationSettings.aliasVerb}
                    engagement={engagement.engageable}
                  />
                </div>
              );
            } else if (engagement.engageableType === 'QuestionSchedule') {
              return (
                <div key={key} className="mb-6">
                  <QuestionActivity
                    id={key}
                    key={key}
                    questionSchedule={engagement.engageable}
                  />
                </div>
              );
            } else if (engagement.engageableType === 'Answer') {
              return (
                <div key={key} className="mb-6">
                  <AnswerActivity
                    id={key}
                    key={key}
                    answer={engagement.engageable}
                  />
                </div>
              );
            }

            return (
              <div key={key} className="mb-6">
                <GeneralActivity
                  id={key}
                  key={key}
                  title=""
                  engagement={engagement.engageable}
                />
              </div>
            );
          })}
        </InfiniteScroll>
      </ul>
    </ErrorBoundary>
  );
};

export default Engagements;
