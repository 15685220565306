import React, { useEffect, useState } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

import { ErrorBoundary, Tile } from '.';
import { chunk } from '../utils/arrayUtils';
import Section from './Section';

const CORE_VALUE_STATISTICS_QUERY = gql`
  query coreValueStatisticsQuery {
    company {
      id
      coreValues {
        id
        value
        hashtag
      }
      groupedPraises(groupBy: "coreValue")
    }
  }
`;

const CoreValueStatistics: React.FC<{
  animatedCoreValue?: { coreValue: CoreValue | undefined; timestamp: number };
}> = ({ animatedCoreValue }) => {
  const [animatedClass, setAnimatedClass] = useState<string>('');

  useEffect(() => {
    setAnimatedClass('animated tada');

    const animationTimer = setTimeout(() => {
      setAnimatedClass('');
    }, 1000);

    return () => clearTimeout(animationTimer);
  }, [animatedCoreValue]);

  const { loading, data } = useQuery(CORE_VALUE_STATISTICS_QUERY);

  if (loading || !data) {
    return null;
  }

  const { coreValues, groupedPraises } = data.company;

  return (
    <ErrorBoundary>
      <Section title="Core Values">
        {coreValues.length > 0 ? (
          chunk(coreValues, 2)?.map(
            (coreValueArray: CoreValue[], idx: number) => {
              return (
                <div key={idx} className="flex flex-row -mx-2">
                  {coreValueArray.map((coreValue: CoreValue) => (
                    <Tile
                      key={coreValue.id}
                      containerClassName={`mb-4 mt-4 sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 px-2 ${
                        animatedCoreValue &&
                        animatedCoreValue.coreValue?.id === coreValue.id
                          ? animatedClass
                          : ''
                      }`}
                      contentClassName="h-48 py-6 flex flex-col justify-center text-center font-semibold"
                    >
                      <div className="text-primary mb-3 text-lg">
                        {coreValue.hashtag}
                      </div>
                      <div>
                        {groupedPraises && (
                          <>
                            <div className="text-5xl font-bold">
                              {groupedPraises[coreValue.value] || 0}
                            </div>
                            <div className="font-light">Praises</div>
                          </>
                        )}
                      </div>
                    </Tile>
                  ))}
                </div>
              );
            }
          )
        ) : (
          <div>
            You haven't setup your Core Values. Click Here to do that now!
          </div>
        )}
      </Section>
    </ErrorBoundary>
  );
};

export default CoreValueStatistics;
