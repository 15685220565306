import React from 'react';

const Alert: React.FC<{
  className?: string;
  show?: boolean;
  type?: 'success' | 'warning' | 'danger' | 'info';
}> = ({ children, className = '', show = true, type }) => {
  let color = 'bg-orange-100 border-orange-400 ';

  if (!show) {
    return null;
  }

  return (
    <div className={`border-l-4 p-4 animated fadeIn ${color} ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0"></div>
        <div className="ml-3">{children}</div>
      </div>
    </div>
  );
};

export default Alert;
