import { Context, createContext } from 'react';

interface NavigationContextType {
  mobileMenuOpen: boolean;
  navMenuOpen: boolean;
  toggleMobileMenu: (open?: boolean) => void;
  toggleNavMenu: (open?: boolean) => void;
}

const navMenuOpen: boolean = false;

export const initialContext = {
  mobileMenuOpen: false,
  navMenuOpen,
  toggleMobileMenu: (open?: boolean) => {},
  toggleNavMenu: (open?: boolean) => {},
};

const NavigationContext: Context<NavigationContextType> = createContext(
  initialContext
);

export default NavigationContext;
