import React from 'react';
import { Moment } from 'moment';
import { DatePicker as AntDatePicker } from 'antd';

import { Label } from '..';
import Error from './Error';

const TextInput: React.FC<{
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  error?: any;
  hint?: string;
  label?: string;
  labelClassName?: string;
  onChange: (event: any) => void;
  placeholder?: string;
  required?: boolean;
  value?: Moment;
}> = ({
  autoComplete,
  className = '',
  disabled = false,
  error,
  hint,
  label,
  labelClassName,
  onChange,
  placeholder,
  required = false,
  value,
}) => {
  let inputClasses =
    'appearance-none block bg-gray-100 text-gray-700 rounded border border-gray-300 py-1 px-0 leading-tight placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out';

  if (error) {
    inputClasses = `${inputClasses} border-red-300 text-red-900`;
  }

  return (
    <div>
      <div className="relative">
        {label && (
          <Label
            className={labelClassName}
            hint={hint}
            label={label}
            required={required}
          />
        )}
        <AntDatePicker
          value={value}
          defaultValue={value}
          className={`${inputClasses} ${className}`}
          onChange={onChange}
          format="MM/DD/YYYY"
          placeholder={placeholder || 'Select Date'}
        />
      </div>
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default TextInput;
