import React from 'react';
import { FiCheck } from 'react-icons/fi';

import { Button } from '.';

const ActionCard: React.FC<{
  type: 'horizontal' | 'vertical';
  completed?: boolean;
  image: any;
  imageAlt: string;
  title: string;
  description: string;
  className?: string;
  buttonTitle?: string;
  buttonAction?: () => void;
}> = ({
  type,
  completed,
  image,
  imageAlt,
  title,
  description,
  className = '',
  buttonTitle,
  buttonAction,
}) => {
  if (type === 'horizontal') {
    return (
      <div
        className={`relative border-gray-200 border rounded-md px-4 py-3 flex flex-row items-center ${className}`}
      >
        <img className="h-24 w-24" src={image} alt={imageAlt} />
        <div className="ml-3">
          <div className="font-semibold text-lg text-orange-600">{title}</div>
          <div className="text-black">{description}</div>
        </div>
        {completed && (
          <div className="flex flex-row items-center px-3 py-1 bg-orange-200 text-xs text-primary font-semibold rounded-l-sm absolute right-0 top-0 mt-6">
            <FiCheck className="mr-1" /> Completed
          </div>
        )}
      </div>
    );
  } else if (type === 'vertical') {
    return (
      <div
        className={`border-gray-200 border rounded-md px-4 py-3 ${className}`}
      >
        <img className="h-16 w-16" src={image} alt={imageAlt} />
        <div className="font-bold text-lg">{title}</div>
        <div className="text-base">{description}</div>
        <Button
          className="mt-4 w-full"
          type="secondary"
          label={buttonTitle!}
          onClick={buttonAction!}
        />
      </div>
    );
  }
  return null;
};

export default ActionCard;
