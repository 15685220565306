export const slackAuthURL = (state: string = '', redirectUri: string = '') => {
  return `https://slack.com/oauth/v2/authorize?client_id=${
    process.env.REACT_APP_SLACK_CLIENT_ID
  }&scope=channels:history,channels:read,im:read,reactions:read,channels:manage,im:write,app_mentions:read,chat:write,channels:join,chat:write.customize,dnd:read,im:history,mpim:history,mpim:read,team:read,users.profile:read,users:read.email,users:read,users:write,mpim:write,commands,emoji:read&state=${state}${
    process.env.REACT_APP_SLACK_REDIRECT_URI !== ''
      ? `&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}`
      : ''
  }`;
};

export const slackExchangeURL = (code: string | undefined) => {
  return `https://slack.com/api/oauth.v2.access?client_id=${
    process.env.REACT_APP_SLACK_CLIENT_ID
  }&client_secret=${process.env.REACT_APP_SLACK_CLIENT_SECRET}&code=${code}${
    process.env.REACT_APP_SLACK_REDIRECT_URI !== ''
      ? `&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}`
      : ''
  }`;
};
