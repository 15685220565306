import React from 'react';

import { Button, Modal } from '.';

const ConfirmModal: React.FC<{
  actionTitle?: string;
  open: boolean;
  title: string;
  onSubmit: () => void;
  onCancel: () => void;
}> = ({
  actionTitle = 'Delete',
  open,
  title,
  children,
  onSubmit,
  onCancel,
}) => {
  return (
    <Modal open={open}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <svg
            className="h-6 w-6 text-red-600"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
          <div className="mt-2">
            <p className="text-sm leading-5 text-gray-500">{children}</p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <Button type="danger" label={actionTitle} onClick={onSubmit} />
        </span>
        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <Button type="secondary" label="Cancel" onClick={onCancel} />
        </span>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
