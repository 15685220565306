import React from 'react';

import CrewLogo from '../../assets/img/crew-mark.svg';

const QuestionActivity: React.FC<{
  id: string;
  questionSchedule: QuestionSchedule;
}> = ({ id, questionSchedule }) => {
  const { question } = questionSchedule;

  return (
    <li key={id}>
      <div className="relative pb-8">
        <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
        <div className="relative flex items-start space-x-3">
          <div>
            <div className="relative px-1">
              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                <img className="h-8 w-10 rounded-full bg-white flex items-center justify-center ring-8 ring-white" src={CrewLogo} alt="Question"/>
              </div>
            </div>
          </div>
          <div className="min-w-0 flex-1 py-1.5">
            <div className="text-sm text-gray-500">
              <span>Your Crew wants to know: </span><span className="font-medium text-gray-900">{question.question}</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default QuestionActivity;
