import React from 'react';
import Emoji from 'react-emoji-render';
import { Link } from '@reach/router';

import { Avatar } from '..';
import { stringToLongDateTime } from '../../utils/dataUtils';

const GeneralActivity: React.FC<{
  id: string;
  title: string;
  verb?: string;
  engagement: Engagement;
}> = ({ id, verb = '', title = '', engagement }) => {
  const { value, createdAt, user } = engagement;

  return (
    <li key={id}>
      <div className="relative pb-8">
        <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
        <div className="relative flex items-start space-x-3">
          <div className="relative">
            <Avatar user={user} size="md" className="bg-gray-400 flex items-center justify-center ring-8 ring-white" />
          </div>
          <div className="min-w-0 flex-1">
            <div>
              <div className="text-sm text-gray-500">
                <Link to={`/crew/${user.id}`} className="font-medium text-gray-900">{user.name}</Link>{' '}{title}
              </div>
              <p className="mt-0.5 text-sm text-gray-500 mb-0">
                {stringToLongDateTime(createdAt)}
              </p>
            </div>
            <div className="mt-2 text-sm text-gray-700">
              <p className="mb-0">
                {`${user.name} ${verb}`} <Emoji text={value} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default GeneralActivity;
