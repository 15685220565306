import React, { lazy } from 'react';
import ReactGA from 'react-ga';
import { Router, createHistory } from '@reach/router';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY || '');
// @ts-ignore
const history = createHistory(window);

history.listen((window) => {
  ReactGA.pageview(window.location.pathname + window.location.search);
});

// @ts-ignore
const Empty = ({ children, path }) => {
  return children;
};

const AlphaRoute = lazy(() => import('./AlphaRoute'));
const FourOhFour = lazy(() => import('./FourOhFour'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const PrivateAdminRoute = lazy(() => import('./PrivateAdminRoute'));
const PrivateLeadRoute = lazy(() => import('./PrivateLeadRoute'));
const PrivateClientRoute = lazy(() => import('./PrivateClientRoute'));

const Home = lazy(() => import('../screens/Home'));
const Search = lazy(() => import('../screens/Search'));
const Welcome = lazy(() => import('../screens/Welcome'));
const SquadValueTime = lazy(() => import('../screens/SquadValueTime/SquadValueTime'));
const IssueQueue = lazy(() => import('../screens/IssueQueue'));

// Auth
const Profile = lazy(() => import('../screens/User/Profile'));
const ForgotPassword = lazy(() => import('../screens/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../screens/Auth/ResetPassword'));
const SignIn = lazy(() => import('../screens/Auth/SignIn'));
const SignUpStep = lazy(() => import('../screens/Auth/SignUp/CompanyStep'));
const InviteUserStep = lazy(
  () => import('../screens/Auth/SignUp/InviteUsersStep')
);
const IntegrationsStep = lazy(
  () => import('../screens/Auth/SignUp/IntegrationsStep')
);
const AcceptInvite = lazy(() => import('../screens/Auth/AcceptInvite'));

// Integrations
const OAuthSlack = lazy(
  () => import('../screens/Integrations/Slack/OAuthSlack')
);

// Crew
const CrewHome = lazy(() => import('../screens/Crew/CrewHome'));
const CrewMap = lazy(() => import('../screens/Crew/CrewMap'));
const CrewMember = lazy(() => import('../screens/Crew/CrewMember'));
const TeamsHome = lazy(() => import('../screens/Crew/TeamsHome'));
const Team = lazy(() => import('../screens/Crew/Team'));
const Interest = lazy(() => import('../screens/Crew/Interest'));
const Skill = lazy(() => import('../screens/Crew/Skill'));

// Goals
const Period = lazy(() => import('../screens/Goals/Period'));
const PeriodsHome = lazy(() => import('../screens/Goals/PeriodsHome'));
const GoalsHome = lazy(() => import('../screens/Goals/GoalsHome'));
const Objective = lazy(() => import('../screens/Goals/Objective'));
const EditObjective = lazy(() => import('../screens/Goals/EditObjective'));
const KeyResult = lazy(() => import('../screens/Goals/KeyResult'));
const NewKeyResult = lazy(() => import('../screens/Goals/NewKeyResult'));
const EditKeyResult = lazy(() => import('../screens/Goals/EditKeyResult'));

// Recognize
const RecognizeSettings = lazy(
  () => import('../screens/Recognize/RecognizeSettings')
);
const PraiseHome = lazy(() => import('../screens/Praises/PraiseHome'));
const CelebrationHome = lazy(
  () => import('../screens/Celebrations/CelebrationHome')
);

// Engage
const EngageHome = lazy(() => import('../screens/Engage/EngageHome'));
const EngageSettings = lazy(() => import('../screens/Engage/EngageSettings'));
const QuestionsHome = lazy(() => import('../screens/Questions/QuestionsHome'));
const Question = lazy(() => import('../screens/Questions/Question'));
const QuotesHome = lazy(() => import('../screens/Quotes/QuotesHome'));
const InquiriesHome = lazy(() => import('../screens/Inquiries/InquiriesHome'));
const NewInquiry = lazy(() => import('../screens/Inquiries/NewInquiry'));

// Events
const EventsHome = lazy(() => import('../screens/Events/EventsHome'));
const NewEvent = lazy(() => import('../screens/Events/NewEvent'));

// Expenses
const ExpensesHome = lazy(() => import('../screens/Expenses/ExpensesHome'));
const Expense = lazy(() => import('../screens/Expenses/Expense'));

// Projects
const Project = lazy(() => import('../screens/Projects/Project'));
const ProjectsHome = lazy(() => import('../screens/Projects/ProjectsHome'));
const Sprint = lazy(() => import('../screens/Projects/Sprints/Sprint'));
const Certificate = lazy(() => import('../screens/Projects/Certificate'));

// Company
const UsersHome = lazy(() => import('../screens/Company/UsersHome'));
const SettingsHome = lazy(() => import('../screens/Company/SettingsHome'));

// Reports
const DEI = lazy(() => import('../screens/Reports/DEI'));
const Potential = lazy(() => import('../screens/Reports/Potential'));
const Recognition = lazy(() => import('../screens/Reports/Recognition'));
const Teams = lazy(() => import('../screens/Reports/Teams'));
const TeamProfitability = lazy(
  () => import('../screens/Reports/TeamProfitability')
);
const ServiceProfitability = lazy(
  () => import('../screens/Reports/ServiceProfitability')
);
const ClientProfitability = lazy(
  () => import('../screens/Reports/ClientProfitability')
);
const CashFlow = lazy(() => import('../screens/Reports/CashFlow'));
const Billability = lazy(() => import('../screens/Reports/Billability'));
const Finance = lazy(() => import('../screens/Reports/Finance'));
const FinanceForecasting = lazy(() => import('../screens/Reports/FinanceForecasting'));
const FinanceSimulator= lazy(() => import('../screens/Reports/FinanceSimulator'));
const ProjectResults = lazy(() => import('../screens/Reports/ProjectResults'));
const ProjectResultsByPeriod = lazy(
  () => import('../screens/Reports/ProjectResultsByPeriod')
);
const ProfitGenerated = lazy(
  () => import('../screens/Reports/ProfitGenerated')
);
const Rates = lazy(() => import('../screens/Reports/Rates'));
const RateSimulator = lazy(() => import('../screens/Reports/RateSimulator'));
const RateSimulatorProjects = lazy(
  () => import('../screens/Reports/RateSimulatorProjects')
);
const TeamsProfitability = lazy(
  () => import('../screens/Reports/TeamsProfitability')
);

// Passengers
const Helm = lazy(() => import('../screens/Passengers/Helm'));
const PassengersHome = lazy(
  () => import('../screens/Passengers/PassengersHome')
);

const Routes = () => {
  return (
    <React.Suspense fallback={<div className="routes" />}>
      <Router>
        <FourOhFour default />
        <PrivateRoute as={Home} path="/" />
        <PrivateRoute as={Search} path="/search" />
        <PrivateRoute as={Welcome} path="/welcome" />
        {/* TODO: Commenting out temporarily since we need a new role to be made to squad lead or higher, this means an officer */}
        {/* <PrivateLeadRoute as={SquadValueTime} path="/squad-value-time" /> */}
        <PrivateRoute as={SquadValueTime} path="/squad-value-time" />
        <PrivateRoute as={IssueQueue} path="/issue-queue" />
        <Empty path="crew">
          <PrivateRoute as={CrewHome} path="/" />
          <PrivateRoute as={CrewMap} path="/map" />
          <PrivateRoute as={CrewMember} path="/:userId" />
          <PrivateRoute as={TeamsHome} path="/teams" />
          <PrivateRoute as={Team} path="/teams/:teamId" />
          <PrivateRoute as={Interest} path="/interests/:interest" />
          <PrivateRoute as={Skill} path="/skills/:skill" />
        </Empty>
        <Empty path="recognize">
          <PrivateAdminRoute as={RecognizeSettings} path="settings" />

          <Empty path="praise">
            <PrivateRoute as={PraiseHome} path="/" />
          </Empty>
          <Empty path="celebrate">
            <PrivateRoute as={CelebrationHome} path="/" />
          </Empty>
        </Empty>
        <Empty path="engage">
          <PrivateRoute as={EngageHome} path="/" />
          <Empty path="questions">
            <PrivateRoute as={QuestionsHome} path="/" />
            <PrivateRoute as={Question} path="/:questionId" />
          </Empty>
          <Empty path="quotes">
            <PrivateRoute as={QuotesHome} path="/" />
          </Empty>
          <Empty path="inquiries">
            <PrivateRoute as={InquiriesHome} path="/" />
            <PrivateRoute as={NewInquiry} path="/new" />
          </Empty>
          <PrivateAdminRoute as={EngageSettings} path="settings" />
        </Empty>
        <Empty path="goals">
          <AlphaRoute as={GoalsHome} path="/" />
          <Empty path="periods">
            <AlphaRoute as={PeriodsHome} path="/" />
            <AlphaRoute as={Period} path=":periodId" />
            <AlphaRoute
              as={Objective}
              path=":periodId/objectives/:objectiveId"
            />
            <AlphaRoute
              as={EditObjective}
              path=":periodId/objectives/:objectiveId/edit"
            />
            <AlphaRoute
              as={KeyResult}
              path=":periodId/objectives/:objectiveId/key-results/:keyResultId"
            />
            <AlphaRoute
              as={NewKeyResult}
              path=":periodId/objectives/:objectiveId/key-results/new"
            />
            <AlphaRoute
              as={EditKeyResult}
              path=":periodId/objectives/:objectiveId/key-results/:keyResultId/edit"
            />
          </Empty>
        </Empty>
        <Empty path="events">
          <AlphaRoute as={EventsHome} path="/" />
          <AlphaRoute as={NewEvent} path="/new" />
        </Empty>
        <Empty path="expenses">
          <PrivateLeadRoute as={ExpensesHome} path="/" />
          <PrivateLeadRoute as={Expense} path="/new" />
          <PrivateLeadRoute as={Expense} path="/:expenseId" />
        </Empty>
        <Empty path="projects">
          <PrivateRoute as={ProjectsHome} path="/" />
          <PrivateRoute as={Project} path=":projectId" />
          <PrivateRoute as={Sprint} path=":projectId/sprints/:sprintId" />
          <PrivateRoute as={Certificate} path="/certificates/new" />
          <PrivateRoute
            as={Certificate}
            path=":projectId/certificates/:certificateId"
          />
        </Empty>
        <Empty path="reports">
          <PrivateAdminRoute as={DEI} path="/dei" />
          <PrivateLeadRoute as={Potential} path="/potential" />
          <PrivateLeadRoute as={Recognition} path="/recognition" />
          <PrivateAdminRoute as={Teams} path="/teams" />
          <PrivateAdminRoute
            as={TeamProfitability}
            path="/teams/:teamId/profitability"
          />
          <PrivateLeadRoute
            as={ServiceProfitability}
            path="/service-profitability"
          />
          <PrivateLeadRoute
            as={ClientProfitability}
            path="/client-profitability"
          />
          <PrivateLeadRoute as={CashFlow} path="/cash-flow" />
          <PrivateRoute as={Billability} path="/billability" />
          <PrivateRoute as={ProjectResults} path="/project-results" />
          <PrivateRoute
            as={ProjectResultsByPeriod}
            path="/project-results-by-period/:projectId"
          />
          <PrivateRoute as={RateSimulatorProjects} path="/rate-simulator" />
          <PrivateRoute as={RateSimulator} path="/rate-simulator/:projectId" />
          <PrivateRoute as={Rates} path="/rates" />
          <PrivateLeadRoute as={ProfitGenerated} path="/profit-generated" />
          <PrivateLeadRoute as={Finance} path="/finance" />
          <PrivateLeadRoute as={FinanceForecasting} path="/finance-forecasting" />
          <PrivateLeadRoute as={FinanceSimulator} path="/finance-simulator" />
          <PrivateLeadRoute
            as={TeamsProfitability}
            path="/teams-profitability"
          />
        </Empty>
        <Empty path="company">
          <PrivateAdminRoute as={SettingsHome} path="/settings" />
          <PrivateAdminRoute as={UsersHome} path="/users" />
        </Empty>
        <PrivateRoute as={Profile} path="/profile" />

        <Empty path="passengers">
          <PrivateRoute as={PassengersHome} path="/" />
        </Empty>

        <PrivateClientRoute as={Helm} path="/helm" />

        <OAuthSlack path="oauth/slack" />

        <SignIn path="sign-in" />
        <ForgotPassword path="forgot-password" />
        <ResetPassword path="reset-password" />
        <Empty path="sign-up">
          <SignUpStep path="/company" />
          <PrivateRoute as={InviteUserStep} path="/invite" />
          <PrivateRoute as={IntegrationsStep} path="/integrations" />
        </Empty>

        <AcceptInvite path="users/accept-invite" />
      </Router>
    </React.Suspense>
  );
};

export default Routes;
