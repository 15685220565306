import React from 'react';

import { slackAuthURL } from '../utils/integrationUtils';

const SlackButton: React.FC<{
  className?: string;
  state?: string;
}> = ({ className = '', state }) => {
  return (
    <a href={slackAuthURL(state || '0')}>
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  );
};

export default SlackButton;
