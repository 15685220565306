import React from 'react';

const LinkButton: React.FC<{
  className?: string;
  color?: string;
  onClick: () => void;
  title?: string;
}> = ({
  children,
  className = '',
  color = 'text-primary',
  onClick,
  title = '',
}) => {
  return (
    <button
      title={title}
      type="button"
      className={`text-left focus:outline-none ${color} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default LinkButton;
