export const clearAuthenticationToken = () => localStorage.removeItem('token');

export const setAuthenticationToken = (token: string) =>
  localStorage.setItem('token', token);

export const authenticationTokenExists = () =>
  localStorage.getItem('token') && localStorage.getItem('token') !== '';

export const userIsAtLeastLead = (role: string) => {
  return (
    role === 'lead' ||
    role === 'admin' ||
    role === 'owner' ||
    role === 'super_admin'
  );
};

export const userIsAtLeastAdmin = (role: string) => {
  return role === 'admin' || role === 'owner' || role === 'super_admin';
};

export const userIsOwner = (role: string) => {
  return role === 'owner' || role === 'super_admin';
};

export const userIsPassenger = (type: string) => {
  return type === 'passenger';
};
