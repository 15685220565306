import React from 'react';

import { Label, Suffix } from '../';
import Error from './Error';

const NumberInput: React.FC<{
  className?: string;
  disabled?: boolean;
  error?: any;
  label?: string;
  max?: string;
  min?: string;
  name?: string;
  onBlur?: (event: any) => void;
  onChange: (event: any) => void;
  placeholder?: string;
  required?: boolean;
  suffix?: string | React.ReactNode;
  value: string | null | undefined;
}> = ({
  className = '',
  disabled = false,
  error,
  label,
  max,
  min,
  name,
  onBlur,
  onChange,
  placeholder,
  required = false,
  suffix,
  value,
}) => {
  let inputClasses =
    'appearance-none block bg-gray-100 text-gray-700 rounded border border-gray-200 py-3 px-4 leading-tight focus:outline-none';

  if (disabled) {
    inputClasses += ' cursor-not-allowed';
  }

  if (error) {
    inputClasses += ' border-red-300 text-red-900';
  }

  return (
    <div>
      <div className="relative">
        {label && <Label label={label} required={required} />}
        <input
          className={`${inputClasses} ${className}`}
          disabled={disabled}
          id={`grid-${name}`}
          max={max}
          min={min}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          type="number"
          value={value || ''}
        />
        {suffix && <Suffix suffix={suffix} />}
      </div>
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default NumberInput;
