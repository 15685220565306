import React from 'react';

const Close: React.FC<{ color?: string }> = ({ color = 'text-black' }) => {
  return (
    <svg
      className={`h-6 w-6 ${color}`}
      stroke="currentColor"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};

export default Close;
