import React from 'react';

const Badge: React.FC<{
  className?: string;
  type?: 'success' | 'danger' | 'warning' | undefined;
}> = ({ children, className = '', type }) => {
  let color = 'bg-gray-100 text-gray-800';

  switch (type) {
    case 'success':
      color = 'bg-green-100 text-green-800';
      break;
    case 'danger':
      color = 'bg-red-100 text-red-800';
      break;
    case 'warning':
      color = 'bg-yellow-100 text-yellow-800';
      break;
    default:
      break;
  }

  return (
    <span
      className={`inline-flex items-center px-3 py-1 rounded-md text-sm font-semibold leading-5 ${color} ${className}`}
    >
      {children}
    </span>
  );
};

export default Badge;
