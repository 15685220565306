import React from 'react';

const heightWidth = (size?: string) => {
  let heightWidth = 'h-8 w-8';

  switch (size) {
    case 'xs':
      heightWidth = 'h-6 w-6';
      break;
    case 'sm':
      heightWidth = 'h-8 w-8';
      break;
    case 'md':
      heightWidth = 'h-10 w-10';
      break;
    case 'lg':
      heightWidth = 'h-16 w-16';
      break;
    case 'xl':
      heightWidth = 'h-24 w-24';
      break;
    case 'xxl':
      heightWidth = 'h-32 w-32';
      break;
    default:
      break;
  }

  return heightWidth;
};

const InitialsContainer: React.FC<{
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined;
  className?: string;
}> = ({ children, size = 'sm', className }) => {
  return (
    <div
      className={`animated fadeIn rounded-full flex items-center justify-center text-white ${heightWidth(
        size
      )} ${className}`}
    >
      {children}
    </div>
  );
};

const Initials: React.FC<{
  initials: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined;
  className?: string;
}> = ({ initials, size, className }) => {
  return (
    <InitialsContainer size={size} className={`${className} bg-primary`}>
      {initials}
    </InitialsContainer>
  );
};

export default Initials;
