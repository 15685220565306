export const simplePluralize = (text: string, count: number) =>
  count === 1 ? text : `${text}s`;

export const titleize = (text: string | React.ReactNode) => {
  if (typeof text !== 'string') return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const hashify = (text: string) => {
  return text.toLowerCase().replace(/\s/g, '-');
};

export const pullInitials = (text: string) => {
  const initials = text.split(' ').map((n) => n[0]);
  return [initials[0], initials.pop()].join('');
};
