import React from 'react';

const AvavtarContainer: React.FC<{
  className?: string;
}> = ({ children, className }) => {
  return (
    <div
      className={`animated fadeIn rounded-full flex items-center justify-center bg-primary text-white text-4xl font-thin h-32 w-32 ${className}`}
    >
      {children}
    </div>
  );
};

const AvatarPreview: React.FC<{
  initials: string;
  photoUrl: string | ArrayBuffer | undefined;
  className?: string;
}> = ({ initials, photoUrl, className }) => {
  return photoUrl ? (
    <AvavtarContainer className={className}>
      <img
        className="rounded-full border border-gray-100 h-32 w-32 object-cover"
        src={photoUrl.toString()}
        alt="Avatar Preview"
      />
    </AvavtarContainer>
  ) : (
    <AvavtarContainer className={className}>{initials}</AvavtarContainer>
  );
};

export default AvatarPreview;
