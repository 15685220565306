import React from 'react';

import { Spinner } from './';

const Loading: React.FC<{ fullScreen?: boolean }> = ({ fullScreen = true }) => {
  return (
    <div
      className={`flex justify-center items-center ${
        fullScreen ? 'h-screen' : 'mt-10'
      }`}
    >
      <Spinner />
    </div>
  );
};

export default Loading;
