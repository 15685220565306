import React from 'react';

const Button: React.FC<{
  className?: string;
  disabled?: boolean;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  icon?: React.ReactNode;
  label: string;
  loading?: boolean;
  loadingLabel?: string;
  onClick?: () => void;
  size?: 'xs' | 'sm' | 'base' | 'md' | 'lg';
  type?: 'primary' | 'secondary' | 'danger';
}> = ({
  className = '',
  disabled = false,
  htmlType,
  icon,
  label,
  loading = false,
  loadingLabel,
  onClick,
  size,
  type,
}) => {
  let sizeClasses = 'px-4 py-3 text-base leading-6';

  switch (size) {
    case 'xs':
      sizeClasses = 'px-2.5 py-1.5 text-xs leading-4';
      break;
    case 'sm':
      sizeClasses = 'px-3 py-3 text-sm leading-4';
      break;
    case 'md':
      sizeClasses = 'px-4 py-3 text-base leading-6';
      break;
    case 'lg':
      sizeClasses = 'px-6 py-3 text-base leading-6';
      break;
    default:
      break;
  }

  let colorClasses =
    'text-white bg-primary hover:bg-orange-500 focus:border-orange-700 active:bg-orange-700';

  switch (type) {
    case 'secondary':
      colorClasses =
        'text-orange-600 bg-white border-orange-300 hover:bg-orange-50 focus:border-orange-300 active:bg-orange-200';
      break;
    case 'danger':
      colorClasses =
        'text-white bg-red-600 hover:bg-red-500 focus:border-red-700 active:bg-red-700';
      break;
    default:
      break;
  }

  let disabledClass = disabled ? 'opacity-60 cursor-not-allowed' : '';

  return (
    <span className={`rounded-md text-right ${className}`}>
      <button
        disabled={disabled}
        onClick={onClick}
        type={htmlType || 'button'}
        className={`border border-transparent font-normal rounded focus:outline-none focus:shadow-outline-primary transition ease-in-out duration-150 ${sizeClasses} ${colorClasses} ${className} ${disabledClass}`}
      >
        <div className="flex flex-row items-center justify-center">
          {icon && <div className="mr-2">{icon}</div>}
          <div className="text-center">{loading ? loadingLabel : label}</div>
        </div>
      </button>
    </span>
  );
};

export default Button;
