import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

const Modal: React.FC<{
  open: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}> = ({ open, children, onSubmit, onCancel }) => {
  const [internalOpen, setInternalOpen] = useState<boolean>(open);

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  return (
    <CSSTransition
      appear
      unmountOnExit
      in={internalOpen}
      timeout={200}
      classNames={{
        enter: 'opacity-0',
        enterDone: 'ease-out duration-300 opacity-100',
        exit: 'opacity-100',
        exitDone: 'ease-in duration-200 opacity-0',
      }}
    >
      <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:items-center sm:justify-center z-50 sm:flex">
        <CSSTransition
          appear
          unmountOnExit
          in={internalOpen}
          timeout={200}
          classNames={{
            enter: 'opacity-0',
            enterDone: 'ease-out duration-300 opacity-100',
            exit: 'opacity-100',
            exitDone: 'ease-in duration-200 opacity-0',
          }}
        >
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
        </CSSTransition>

        <CSSTransition
          appear
          unmountOnExit
          in={internalOpen}
          timeout={200}
          classNames={{
            enter: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
            enterDone:
              'ease-out duration-300 opacity-100 translate-y-0 sm:scale-100',
            exit: 'opacity-200 duration-1000 translate-y-0 sm:scale-100',
            exitDone:
              'ease-in duration-1000 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
          }}
        >
          <div className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6">
            {children}
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

export default Modal;
