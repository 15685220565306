import React, {
  Context,
  Dispatch,
  createContext,
  useMemo,
  useReducer,
} from 'react';

interface State {
  loaded: boolean;
  loading: boolean;
  hasWelcomeItems: boolean;
  coreValuesCompleted: boolean;
  slackCompleted: boolean;
  teamsCompleted: boolean;
  usersCompleted: boolean;
  companyCompleted: boolean;
}

const initialState = {
  loaded: false,
  loading: false,
  hasWelcomeItems: false,
  coreValuesCompleted: false,
  slackCompleted: false,
  teamsCompleted: false,
  usersCompleted: false,
  companyCompleted: false,
};

const welcomeReducer = (state: State, action: WelcomeAction) => {
  switch (action.type) {
    case 'networkFetching': {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }
    case 'networkSuccess': {
      return {
        ...state,
        loaded: true,
        loading: false,
        hasWelcomeItems: !!action.payload?.hasWelcomeItems,
        coreValuesCompleted: !!action.payload?.coreValuesCompleted,
        slackCompleted: !!action.payload?.slackCompleted,
        teamsCompleted: !!action.payload?.teamsCompleted,
        usersCompleted: !!action.payload?.usersCompleted,
        companyCompleted: !!action.payload?.companyCompleted,
      };
    }
    case 'networkError': {
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    }
    case 'updateProperty': {
      const newState = {
        ...state,
        ...action.payload,
      };

      const {
        coreValuesCompleted,
        slackCompleted,
        teamsCompleted,
        usersCompleted,
        companyCompleted,
      } = newState;

      if (
        [
          coreValuesCompleted,
          slackCompleted,
          teamsCompleted,
          usersCompleted,
          companyCompleted,
        ].every((b) => b)
      ) {
        return { ...newState, hasWelcomeItems: false };
      } else {
        return newState;
      }
    }
    default:
      return state;
  }
};

export const WelcomeContextProvider: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = useReducer(welcomeReducer, initialState);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <WelcomeContext.Provider value={contextValue}>
      {children}
    </WelcomeContext.Provider>
  );
};

const WelcomeContext: Context<{
  state: State;
  dispatch: Dispatch<WelcomeAction>;
}> = createContext({
  state: initialState,
  dispatch: (_) => {},
});

export default WelcomeContext;
