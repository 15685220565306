import React, { useContext, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Link, useLocation } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faUserGroup,
  faMagnifyingGlass,
  faFileChartColumn,
  faChevronRight,
  faChevronDown,
  faDiagramProject,
  faMoneyCheckDollarPen,
  faUserAstronaut,
} from '@fortawesome/pro-light-svg-icons';

import CrewLogo from '../../assets/img/crew-mark.svg';
import { Avatar } from '..';
import { navigate } from '../../App';
import {
  userIsAtLeastAdmin,
  userIsAtLeastLead,
  userIsPassenger,
} from '../../utils/authUtils';
import {
  AuthContext,
  NavigationContext,
  SearchContext,
  WelcomeContext,
} from '../../context';

const Sidebar: React.FC<{}> = () => {
  const location = useLocation();
  const { me, signOut } = useContext(AuthContext);
  const { state: searchState, dispatch } = useContext(SearchContext);
  const { state: welcomeState } = useContext(WelcomeContext);
  const { mobileMenuOpen, toggleMobileMenu } = useContext(NavigationContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openMenuItem, setOpenMenuItem] = useState('');

  if (
    !me ||
    userIsPassenger(me.type!) ||
    location?.pathname?.includes('sign-up')
  ) {
    return null;
  }

  const hasOnboardingItems =
    userIsAtLeastAdmin(me?.role) && welcomeState.hasWelcomeItems;

  const activeClassName =
    'bg-gray-200 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md';
  const inactiveClassName =
    'text-gray-700 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md';

  const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
    return isCurrent
      ? { className: activeClassName }
      : { className: inactiveClassName };
  };

  const isPartiallyActive = ({
    isPartiallyCurrent,
  }: {
    isPartiallyCurrent: boolean;
  }) => {
    return isPartiallyCurrent
      ? { className: activeClassName }
      : { className: inactiveClassName };
  };

  const isActiveSub = ({ isCurrent }: { isCurrent: boolean }) => {
    return isCurrent
      ? { className: `${activeClassName} pl-11 pr-2 py-2` }
      : { className: `${inactiveClassName} pl-11 pr-2 py-2` };
  };

  const renderNavigation = () => {
    return (
      <nav className="px-3 mt-6">
        <div className="space-y-2">
          <Link
            to="/"
            getProps={isActive}
            onClick={() => toggleMobileMenu(false)}
          >
            <FontAwesomeIcon
              icon={faHouse}
              className="w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
            />
            Home
          </Link>

          <Link
            to="/crew"
            getProps={isPartiallyActive}
            onClick={() => toggleMobileMenu(false)}
          >
            <FontAwesomeIcon
              icon={faUserGroup}
              className="w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
            />
            Crew
          </Link>

          {userIsAtLeastLead(me.role) && (
            <Link
              to="/expenses"
              getProps={isPartiallyActive}
              onClick={() => toggleMobileMenu(false)}
            >
              <FontAwesomeIcon
                icon={faMoneyCheckDollarPen}
                className="w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
              />
              Expenses
            </Link>
          )}

          <Link
            to="/projects"
            getProps={isPartiallyActive}
            onClick={() => toggleMobileMenu(false)}
          >
            <FontAwesomeIcon
              icon={faDiagramProject}
              className="w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
            />
            Projects
          </Link>

          <Link
            to="/passengers"
            getProps={isPartiallyActive}
            onClick={() => toggleMobileMenu(false)}
          >
            <FontAwesomeIcon
              icon={faUserAstronaut}
              className="w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
            />
            Passengers
          </Link>

          {/* TODO: Commenting out temporarily since we need a new role to be made to squad lead or higher, this means an officer */}
          {/* {userIsAtLeastLead(me.role) && (
            <Link
              to="/squad-value-time"
              getProps={isPartiallyActive}
              onClick={() => toggleMobileMenu(false)}
            >
              <FontAwesomeIcon
                icon={faUserGroup}
                className="w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
              />
              Squad Value Time
            </Link>
          )} */}

          <div className="space-y-1">
            <button
              onClick={() =>
                openMenuItem === 'reports'
                  ? setOpenMenuItem('')
                  : setOpenMenuItem('reports')
              }
              className={`flex items-center w-full py-2 pl-2 pr-1 ${inactiveClassName}`}
            >
              <FontAwesomeIcon
                icon={faFileChartColumn}
                className="w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
              />
              Reports
              <FontAwesomeIcon
                icon={
                  openMenuItem === 'reports' ? faChevronDown : faChevronRight
                }
                className="w-3 h-3 pr-2 ml-auto group-hover:text-gray-400"
              />
            </button>
            <div
              className={`space-y-1 ${
                openMenuItem === 'reports' ? 'visible' : 'hidden'
              }`}
            >
              {userIsAtLeastAdmin(me.role) && (
                <>
                  <Link
                    to="/reports/dei"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    DE&I
                  </Link>
                  <Link
                    to="/reports/teams"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Teams
                  </Link>
                </>
              )}
              {userIsAtLeastLead(me.role) && (
                <>
                  <Link
                    to="/reports/client-profitability"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Clients
                  </Link>
                  <Link
                    to="/reports/service-profitability"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Services
                  </Link>
                  <Link
                    to="/reports/cash-flow"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Cash Flow
                  </Link>
                  <Link
                    to="/reports/potential"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Potential
                  </Link>
                  <Link
                    to="/reports/recognition"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Recognition
                  </Link>
                  <Link
                    to="/reports/profit-generated"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Profit Generated
                  </Link>
                  <Link
                    to="/reports/finance"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Finance
                  </Link>
                  <Link
                    to="/reports/finance-simulator"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Finance Simulator
                  </Link>
                  <Link
                    to="/reports/finance-forecasting"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Finance Forecasting
                  </Link>
                  <Link
                    to="/reports/teams-profitability"
                    getProps={isActiveSub}
                    onClick={() => toggleMobileMenu(false)}
                  >
                    Team Profitability
                  </Link>
                </>
              )}
              <Link
                to="/reports/billability"
                getProps={isActiveSub}
                onClick={() => toggleMobileMenu(false)}
              >
                Billability
              </Link>
              <Link
                to="/issue-queue"
                getProps={isActiveSub}
                onClick={() => toggleMobileMenu(false)}
              >
                Issue Queue
              </Link>
              <Link
                to="/reports/project-results"
                getProps={isActiveSub}
                onClick={() => toggleMobileMenu(false)}
              >
                Project Results
              </Link>
              <Link
                to="/reports/rate-simulator"
                getProps={isActiveSub}
                onClick={() => toggleMobileMenu(false)}
              >
                Rate Simulator
              </Link>
              <Link
                to="/reports/rates"
                getProps={isActiveSub}
                onClick={() => toggleMobileMenu(false)}
              >
                Rates
              </Link>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h3
            className="px-3 text-xs font-semibold tracking-wider text-gray-500 uppercase"
            id="teams-headline"
          >
            My team
          </h3>
          <div
            className="mt-2 space-y-0"
            role="group"
            aria-labelledby="teams-headline"
          >
            {me?.team?.users?.edges?.map((edge: any, idx: number) => {
              const user = edge.node;

              if (user.id === me.id) {
                return null;
              }

              return (
                <Link
                  to={`/crew/${user.id}`}
                  key={user.id}
                  className="flex items-center px-3 py-2 text-sm text-gray-700 rounded-md group hover:text-gray-900 hover:bg-gray-50"
                  onClick={() => toggleMobileMenu(false)}
                >
                  <Avatar user={user} size="xs" className="text-xs" />
                  <span className="ml-2 truncate">{user.name}</span>
                </Link>
              );
            })}
          </div>
        </div>
      </nav>
    );
  };

  return (
    <>
      {/*<!-- Mobile Navigation -->*/}
      <div className="lg:hidden">
        <Transition
          show={mobileMenuOpen}
          className="fixed inset-0 z-40 flex"
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Transition
            show={mobileMenuOpen}
            className="fixed inset-0"
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="opacity-0"
            aria-hidden="true"
          >
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </Transition>
          <Transition
            show={mobileMenuOpen}
            className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white"
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="absolute top-0 right-0 pt-2 -mr-12">
              <button
                className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={() => toggleMobileMenu(false)}
              >
                <span className="sr-only">Close sidebar</span>
                <svg
                  className="w-6 h-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex items-center flex-shrink-0 px-4">
              <div className="flex-1">
                <div className="relative w-12 h-12 mx-auto">
                  {hasOnboardingItems && (
                    <span className="absolute top-0.5 right-1.5 block h-3 w-3 transform -translate-y-1/2 translate-x-1/2 rounded-full text-white shadow-solid bg-red-600 border-2 border-white"></span>
                  )}
                  <img className="w-auto h-12" src={CrewLogo} alt="Crew" />
                </div>
              </div>
            </div>
            <div className="flex-1 h-0 mt-5 overflow-y-auto">
              {renderNavigation()}
            </div>
          </Transition>
          <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
        </Transition>
      </div>

      {/* Desktop Navigation */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64 pt-5 pb-4 bg-white">
          <div className="flex items-center flex-shrink-0 px-6">
            <div className="flex-1">
              <div className="relative w-12 h-12 mx-auto">
                {hasOnboardingItems && (
                  <span className="absolute top-0.5 right-1.5 block h-3 w-3 transform -translate-y-1/2 translate-x-1/2 rounded-full text-white shadow-solid bg-red-600 border-2 border-white"></span>
                )}
                <img className="w-auto h-12" src={CrewLogo} alt="Crew" />
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 h-0 overflow-y-auto">
            <div className="relative inline-block px-3 mt-6 text-left">
              <div>
                <button
                  type="button"
                  className="w-full px-2 py-2 text-sm font-medium text-gray-700 bg-white rounded-md group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <span className="flex items-center justify-between w-full">
                    <span className="flex items-center justify-between min-w-0 space-x-3">
                      <Avatar user={me} size="md" className="flex-shrink-0" />
                      <div className="flex-1 min-w-0 text-left">
                        <div className="text-sm font-medium text-gray-900 truncate">
                          {me?.name}
                        </div>
                        <div className="text-sm text-gray-500 truncate">
                          {me?.title}
                        </div>
                      </div>
                    </span>
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <Transition
                show={isDropdownOpen}
                className="absolute left-0 right-0 z-10 mx-3 mt-1 origin-top bg-white divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div className="py-1">
                  <Link
                    to={`/crew/${me?.id}`}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    View Profile
                  </Link>
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    Edit Profile
                  </Link>
                </div>
                <div className="py-1">
                  {userIsAtLeastAdmin(me?.role) && (
                    <>
                      <Link
                        to="/company/users"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        Users
                      </Link>
                      <Link
                        to="/company/settings"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        Settings
                      </Link>
                    </>
                  )}
                </div>
                <div className="py-1">
                  <a
                    href="mailto:trent@teamairship.com"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    Support
                  </a>
                  <Link
                    to="#"
                    role="menuitem"
                    onClick={() => {
                      setIsDropdownOpen(false);
                      signOut();
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Sign Out
                  </Link>
                </div>
              </Transition>
            </div>
            {/*<!-- Sidebar Search -->*/}
            <div className="px-3 mt-5">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <div
                  className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                  aria-hidden="true"
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="w-4 h-4 mr-3 text-gray-400"
                  />
                </div>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();

                    dispatch({
                      type: 'search',
                      payload: {
                        value: searchState.value,
                      },
                    });
                    navigate(`/search?q=${searchState.value}`);
                  }}
                >
                  <input
                    id="search"
                    type="text"
                    name="search"
                    className="block w-full border-gray-300 rounded-md focus:ring-primary focus:border-primary pl-9 sm:text-sm"
                    placeholder="Search"
                    onChange={(event) =>
                      dispatch({
                        type: 'typing',
                        payload: { value: event.target.value },
                      })
                    }
                    value={searchState.value}
                  />
                </form>
              </div>
            </div>
            {/*<!-- Navigation -->*/}
            {renderNavigation()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
