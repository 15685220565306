import React from 'react';

import { Label } from '../';
import Error from './Error';

const Select: React.FC<{
  className?: string;
  error?: any;
  label?: string;
  labelClassName?: string;
  name: string;
  onChange: (event: any) => void;
  renderOptions: Function;
  required?: boolean;
  disabled?: boolean;
  value: string;
}> = ({
  className = '',
  error,
  label,
  labelClassName,
  name,
  onChange,
  renderOptions,
  required = false,
  disabled,
  value,
}) => {
  let inputClassName =
    'block appearance-none w-full bg-gray-100 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500';

  if (error) {
    inputClassName = `${inputClassName} border-red-300 text-red-900`;
  }

  return (
    <div>
      <div className="relative">
        {label && (
          <Label required={required} label={label} className={labelClassName} />
        )}
        <div className="relative w-full">
          <select
            name={name}
            disabled={disabled}
            className={`${inputClassName} ${className}`}
            id={`grid-${name}`}
            onChange={onChange}
            value={value}
          >
            {renderOptions()}
          </select>
        </div>
      </div>
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default Select;
