import React from 'react';

const Text: React.FC<{ className?: string }> = ({
  children,
  className = '',
}) => {
  return (
    <div className={`tracking-wide text-base whitespace-pre-wrap ${className}`}>
      {children}
    </div>
  );
};

export default Text;
