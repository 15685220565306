import React, { useContext } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

import { ErrorBoundary, Tile } from '.';
import { AuthContext } from '../context';
import RemoteRelaxed from '../assets/img/remote-relaxed.svg';

const TODAYS_ANNOUNCEMENTS_QUERY = gql`
  query todaysAnnouncementsQuery {
    company {
      id
      todaysAnnouncements {
        id
        value
      }
    }
  }
`;

const Announcements = () => {
  const { me } = useContext(AuthContext);

  const { loading, data } = useQuery(TODAYS_ANNOUNCEMENTS_QUERY);

  if (loading || !data || !me) {
    return null;
  }

  const { todaysAnnouncements } = data.company;

  if (todaysAnnouncements.length === 0) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Tile containerClassName="mt-6 animated flipInX" contentClassName="p-6">
        <div className="flex flex-row items-center justify-between">
          <div>
            <h2 className="text-primary text-xl">
              Welcome back, {me.firstName}!
            </h2>
            {todaysAnnouncements?.length > 0 ? (
              <div className="max-w-3xl">
                {todaysAnnouncements.map((announcement: Announcement) => (
                  <p key={announcement.id}>{announcement.value}</p>
                ))}
              </div>
            ) : (
              <p className="max-w-3xl">
                There are no new announcements! Be "on purpose" today!
              </p>
            )}
          </div>
          <div>
            <img
              src={RemoteRelaxed}
              alt="Relaxed Remote Worker"
            />
          </div>
        </div>
      </Tile>
    </ErrorBoundary>
  );
};

export default Announcements;
