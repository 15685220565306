import React from 'react';
import { CSSTransition } from 'react-transition-group';

const ContextMenu: React.FC<{ open: boolean; className?: string }> = ({
  open,
  children,
  className = '',
}) => {
  return (
    <CSSTransition
      appear
      unmountOnExit
      in={open}
      timeout={200}
      classNames={{
        enter: 'transform opacity-0 scale-95',
        enterDone:
          'transition ease-out duration-100 transform opacity-100 scale-100',
        exit: 'transform opacity-0 scale-100',
        exitDone:
          'transition ease-in duration-100 transform opacity-0 scale-95',
      }}
    >
      <div
        className={`absolute mt-1 w-56 rounded-lg shadow-lg z-50 ${className}`}
      >
        <div className="py-1 rounded-lg bg-white shadow-xs">{children}</div>
      </div>
    </CSSTransition>
  );
};

export default ContextMenu;
