import React from 'react';
import { Link } from '@reach/router';

import { Text } from '..';
import { stringToLongDateTime } from '../../utils/dataUtils';
import QuoteBackground from '../../assets/img/quote-bg.svg';

const QuoteActivity: React.FC<{
  id: string;
  quote: Quote;
}> = ({ id, quote }) => {
  const { value, author, postedAt } = quote;

  return (
    <div key={id}>
      <div className="flex flex-row items-center">
        <Link to="#">
          <span className="items-center justify-center h-10 w-10 rounded-full bg-gray-400">
            <img
              className="object-cover object-right h-10 w-10 rounded-full"
              src={QuoteBackground}
              alt=""
            />
          </span>
        </Link>
        <div className="ml-3">
          <div className="text-lg leading-5">Today's Quote</div>
          <div className="text-sm font-light text-gray-600">
            {stringToLongDateTime(postedAt)}{' '}
          </div>
        </div>
      </div>

      <div className="flex-1 mt-2">
        <Text>
          "{value}" - {author}
        </Text>
      </div>
    </div>
  );
};

export default QuoteActivity;
