import React, { useContext, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';

import Routes from '../../routes';
import { Avatar } from '../../components';
import { PassengerNavbar } from '.';
import { navigate } from '../../App';
import { userIsAtLeastAdmin, userIsPassenger } from '../../utils/authUtils';
import { AuthContext, NavigationContext, SearchContext } from '../../context';

const Content: React.FC<{}> = () => {
  const { me, signOut } = useContext(AuthContext);
  const { state, dispatch } = useContext(SearchContext);
  const { navMenuOpen, toggleMobileMenu } = useContext(NavigationContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const authClassNames = me
    ? `border-l border-gray-200  ${navMenuOpen ? 'md:ml-56' : 'md:ml-0'}`
    : '';

  return (
    <div className="flex flex-col flex-1 w-0 overflow-hidden">
      <PassengerNavbar />
      {!userIsPassenger(me?.type!) && (
        <div className="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 lg:hidden">
          <button
            className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary lg:hidden"
            onClick={() => toggleMobileMenu(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </button>
          <div className="flex justify-between flex-1 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-1">
              <form
                className="flex w-full md:ml-0"
                onSubmit={(event) => {
                  event.preventDefault();

                  dispatch({
                    type: 'search',
                    payload: {
                      value: state.value,
                    },
                  });
                  navigate(`/search?q=${state.value}`);
                }}
              >
                <label htmlFor="search_field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="w-5 h-5"
                    />
                  </div>
                  <input
                    id="search_field"
                    name="search_field"
                    className="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm"
                    placeholder="Search"
                    type="search"
                    onChange={(event) =>
                      dispatch({
                        type: 'typing',
                        payload: { value: event.target.value },
                      })
                    }
                    value={state.value}
                  />
                </div>
              </form>
            </div>

            <div className="flex items-center">
              <div className="relative ml-3">
                <div>
                  <button
                    id="user-menu"
                    aria-haspopup="true"
                    className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <span className="sr-only">Open user menu</span>
                    <Avatar user={me} size="sm" />
                  </button>
                </div>
                <Transition
                  show={isDropdownOpen}
                  className="absolute right-0 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div className="py-1">
                    <Link
                      to={`/crew/${me?.id}`}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      View Profile
                    </Link>
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      Edit Profile
                    </Link>
                  </div>
                  <div className="py-1">
                    {userIsAtLeastAdmin(me?.role) && (
                      <>
                        <Link
                          to="/company/users"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          Users
                        </Link>
                        <Link
                          to="/company/settings"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          Settings
                        </Link>
                      </>
                    )}
                  </div>
                  <div className="py-1">
                    <a
                      href="mailto:trent@teamairship.com"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      Support
                    </a>
                    <Link
                      to="#"
                      role="menuitem"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        signOut();
                      }}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    >
                      Sign Out
                    </Link>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </div>
      )}
      <main
        className={`flex-1 relative z-0 overflow-y-auto focus:outline-none bg-white rounded-bl-lg shadow-xl transition-all ease-linear duration-75 ${authClassNames}`}
      >
        <Routes />
      </main>
    </div>
  );
};

export default Content;
