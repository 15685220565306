import React from 'react';

const SectionTitle: React.FC<{
  title: string | React.ReactNode;
  className?: string;
  accessory?: string | React.ReactNode;
  underline?: boolean;
}> = ({ title, className = '', accessory, underline = true }) => {
  return (
    <div
      className={`flex flex-row justify-between items-center animated fadeIn ${className}`}
    >
      <div>
        <div>
          <h3 className="font-display text-xl uppercase mb-1">{title}</h3>
        </div>
        {underline && <div className="bg-primary h-1 w-10"></div>}
      </div>
      {accessory}
    </div>
  );
};

export default SectionTitle;
