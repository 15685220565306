import React, {
  Context,
  Dispatch,
  createContext,
  useMemo,
  useReducer,
} from 'react';

interface State {
  value: string;
}

const initialState = {
  value: '',
};

const searchReducer = (state: State, action: SearchAction) => {
  switch (action.type) {
    case 'search': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'typing': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const SearchContextProvider: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};

const SearchContext: Context<{
  state: State;
  dispatch: Dispatch<SearchAction>;
}> = createContext({
  state: initialState,
  dispatch: (_) => {},
});

export default SearchContext;
