import React from 'react';

import SectionTitle from './SectionTitle';

interface SectionProps {
  accessory?: string | React.ReactNode;
  containerClassName?: string;
  contentClassName?: string;
  title?: string;
  titleClassName?: string;
  topPadded?: boolean;
}

const Section: React.FC<SectionProps> = ({
  accessory,
  children,
  containerClassName = '',
  contentClassName = '',
  title,
  titleClassName = '',
  topPadded = true,
}) => {
  return (
    <div
      className={topPadded ? `mt-6 ${containerClassName}` : containerClassName}
    >
      {title && (
        <SectionTitle
          title={title}
          className={`mb-4 ${titleClassName}`}
          accessory={accessory}
        />
      )}
      <div className={`animated fadeIn ${contentClassName}`}>{children}</div>
    </div>
  );
};

export default Section;
