import React from 'react';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/pro-solid-svg-icons';

const Container: React.FC<{
  className?: string;
  padded?: boolean;
  breadCrumbs?: Array<{ title: string; link: string }>;
}> = ({ children, className = '', padded = true, breadCrumbs = [] }) => {
  return (
    <>
      {breadCrumbs.length > 0 && (
        <nav
          className="flex bg-white border-b border-gray-200"
          aria-label="Breadcrumb"
        >
          <ol className="flex w-full max-w-screen-xl px-4 mb-0 space-x-4 sm:px-6 lg:px-8">
            <li className="flex">
              <div className="flex items-center">
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <FontAwesomeIcon
                    icon={faHouse}
                    className="flex-shrink-0 w-5 h-5"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {breadCrumbs.map((crumb: { title: string; link: string }) => (
              <li key={crumb.title} className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link
                    to={crumb.link}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {crumb.title}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      )}

      <div
        className={
          padded ? `pb-10 px-6 pt-6 h-screen ${className}` : `${className}`
        }
      >
        <div className="h-full">{children}</div>
      </div>
    </>
  );
};

export default Container;
