import React from 'react';

const Suffix: React.FC<{ suffix: string | React.ReactNode }> = ({ suffix }) => {
  return (
    <div className="border border-gray-400 py-2 px-2 border-l-0 rounded-tr-sm rounded-br-sm">
      {suffix}
    </div>
  );
};

export default Suffix;
