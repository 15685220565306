import React from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { createHistory, LocationProvider } from '@reach/router';

import { Content, Sidebar } from './components/Layout';
import {
  AuthContextProvider,
  GoalsContextProvider,
  NavigationContextProvider,
} from './context';

import { WelcomeContextProvider } from './context/WelcomeContext';
import { SearchContextProvider } from './context/SearchContext';

// @ts-ignore
export let history = createHistory(window);
export const { navigate } = history;

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: {
        __schema: {
          types: [],
        },
      },
    }),
  }),
  request: (operation) => {
    const token = localStorage.getItem('token');

    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    }
  },
});

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <LocationProvider history={history}>
        <WelcomeContextProvider>
          <NavigationContextProvider>
            <SearchContextProvider>
              <AuthContextProvider>
                <GoalsContextProvider>
                  <div className="flex h-screen overflow-hidden bg-gray-100">
                    <Sidebar />
                    <Content />
                  </div>
                </GoalsContextProvider>
              </AuthContextProvider>
            </SearchContextProvider>
          </NavigationContextProvider>
        </WelcomeContextProvider>
      </LocationProvider>
    </ApolloProvider>
  );
};

export default App;
