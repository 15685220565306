import React from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

import { Spinner } from './';

const AVATAR_QUERY = gql`
  query avatarQuery($id: ID!) {
    user(id: $id) {
      id
      name
      initials
      photoUrl
      smallPhotoUrl
    }
  }
`;

const heightWidth = (size?: string) => {
  let heightWidth = 'h-8 w-8';

  switch (size) {
    case 'xs':
      heightWidth = 'h-6 w-6';
      break;
    case 'sm':
      heightWidth = 'h-8 w-8';
      break;
    case 'md':
      heightWidth = 'h-10 w-10';
      break;
    case 'lg':
      heightWidth = 'h-16 w-16';
      break;
    case 'xl':
      heightWidth = 'h-24 w-24';
      break;
    case 'xxl':
      heightWidth = 'h-32 w-32';
      break;
    default:
      break;
  }

  return heightWidth;
};

const AvatarContainer: React.FC<{
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined;
  className?: string;
}> = ({ children, size = 'sm', className }) => {
  return (
    <div
      className={`animated fadeIn rounded-full flex items-center justify-center text-white ${heightWidth(
        size
      )} ${className}`}
    >
      {children}
    </div>
  );
};

const Avatar: React.FC<{
  user: User | { id: number };
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined;
  className?: string;
  overrideInitials?: string;
}> = ({ user, size, className, overrideInitials }) => {
  const { loading, data } = useQuery(AVATAR_QUERY, {
    variables: { id: user?.id },
  });

  if (loading || !data) {
    if (overrideInitials) {
      return (
        <AvatarContainer size={size} className={`${className} bg-primary`}>
          {overrideInitials}
        </AvatarContainer>
      );
    }

    return (
      <AvatarContainer size={size} className={`${className} bg-primary`}>
        <Spinner size={20} color="#FFFFFF" />
      </AvatarContainer>
    );
  }

  const { name, initials, photoUrl } = data.user;

  return photoUrl ? (
    <AvatarContainer size={size} className={className}>
      <img
        className={`rounded-full border border-gray-100 object-cover ${heightWidth(
          size
        )}`}
        src={photoUrl}
        alt={name}
      />
    </AvatarContainer>
  ) : (
    <AvatarContainer size={size} className={`${className} bg-primary`}>
      {initials}
    </AvatarContainer>
  );
};

export default Avatar;
